@import 'src/styles/main';

.wrapper {
  padding: 24px;
}

.title {
  @include H4_medium;
}

.main {
  margin-top: 24px;
  box-shadow: 0 2px 8px 0 #00000026;
  padding: 42px;
  border-radius: 16px;
  height: calc(100vh - 100px);
  overflow: auto;
}

.loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}