@import '../../../styles/main';

.form {
  margin-top: 51px;
  :global {
    .ant-form-item {
      margin-bottom: 0 !important;
    }
  }
}

.formCheckboxWrapper {
  display: flex;
  justify-content: flex-end;
  &_link {
    @include Body_medium;
    color: #00000073;
    cursor: pointer;
  }
}

.formItem {
  margin-top: 51px;
  &_button {
    @include Main_button;
    width: 200px;
    height: 38px;
  }
}

.error {
  font-size: 16px;
  line-height: 20px;
  color: red;
}